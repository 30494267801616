// output a css property with a responsive map of values
@mixin res-prop($prop: "margin-bottom", $values: (xs: 1rem), $factor: 1, $important: false, $roundValues: false) {
  $_important: "";
  @if ($important) {
    $_important: "!important";
  }
  @each $breakpoint in map-keys($values) {
    @include media-breakpoint-up($breakpoint) {
      $mediaFactor : $factor;
      @if type-of($factor) == 'map' {
        $mediaFactor : map-get($factor, $breakpoint);
      }
      $value : map-get($values, $breakpoint) * $mediaFactor;
      @if $roundValues == true {
        $value : round($value);
      }
      #{$prop}: $value #{$_important};
    }
  }
}
