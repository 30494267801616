.OverlayMenu {
  position: fixed;
  z-index: $zindex-overlay-menu;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  display: none;
  ul {
    list-style: none;
    padding: 0;
  }
  p {
    // margin: 0;
  }
  a {
    transition: opacity .15s;
    .desktop &:hover {
      text-decoration: none;
      opacity: .5;
    }
  }
  [data-menu-stagger] {
    opacity: 0;
  }
}

.OverlayMenu-content {
  /* ie11 fix */
  display: flex;
  flex-direction: column;
}

.OverlayMenu-container {
  @include make-container();
  @include make-container-max-widths();
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.OverlayMenu-scroll {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.OverlayMenu-main,
.OverlayMenu-footer {
  @include make-row();
}

.OverlayMenu-main {
  padding-top: 100px;
  @include media-breakpoint-up(sm) {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.OverlayMenu-footer {
  @include media-breakpoint-up(sm) {
    margin-top: auto;
    margin-bottom: 2rem;
  }
}

.OverlayMenu-block {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
}

.OverlayMenu-block--main {
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.OverlayMenu-block--aside {

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.OverlayMenu-navList {
  margin-bottom: 1rem;
}

.OverlayMenu-navItem {
  @include heading("2");
  line-height: 1;
  padding: .1em 0;
  a {
    color: #fff;
    opacity: 1 !important;
    .desktop & {
      background-image: linear-gradient(currentColor,currentColor);
      background-size: 0 2px;
      background-repeat: no-repeat;
      background-position: 0 74%;
      transition: background-size .6s cubic-bezier("easeInOutQuint");
      &:hover {
        background-size: 100% 2px;
      }
    }
  }
  &.is-active a {
    color: $color-cta-primary;
  }
}

.OverlayMenu-contact,
.OverlayMenu-languageList {
  font-size: calc(1rem - 2px);
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

.OverlayMenu-contact {
  margin-bottom: 1rem;
}

.OverlayMenu-languageList {
  display: flex;
  margin-bottom: 1rem;
}

.OverlayMenu-languageItem {
  &:not(:first-child) {
    margin-left: 1.5em;
    position: relative;
    &:before {
      position: absolute;
      left: -0.9em;
      content: '/';
      // padding-left: 0.5em;
      // margin-right: 0.5em;
    }
  }
  &.is-active {
    text-decoration: underline;
  }
}

.OverlayMenu-socialItem {
  display: inline;
}

.OverlayMenu-socialList,
.OverlayMenu-colophon {
  font-size: calc(1rem - 4px);
  text-transform: uppercase;
  @include media-breakpoint-up(sm) {
    font-size: calc(1rem - 2px);
  }
}

.OverlayMenu-socialList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
}

.OverlayMenu-socialItem {
  @include media-breakpoint-up(sm) {
    margin-right: 1em;
  }
}

.OverlayMenu-colophon {
  margin-bottom: 1rem;
}
