.Heading {
  @include heading();
}

.Heading--display {
  @include heading("display", false);
}

.Heading--1 {
  @include heading("1", false);
}

.Heading--2 {
  @include heading("2", false);
}

.Heading--3 {
  @include heading("3", false);
}

.Heading--4 {
  @include heading("4", false);
}

.Heading--5 {
  @include heading("5", false);
}

.Heading--context {
  @include heading("context", false);
}
