h1, h2, h3, h4, h5, h6 {
}

p, ol, ul {
}

.text-white {
  color: #fff;
}

.text-dark {
  color: $color-text;
}

.text-danger {
  color: $color-danger;
}

.text-gradient {
  display: block;
  background: -webkit-radial-gradient(tint(color("raw-sienna"), 30%), color("dark-sienna"));
  // background: -webkit-radial-gradient(#000, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*
hr {
  @include contentSpacer("margin-top");
  @include contentSpacer("margin-bottom");
  border: 0;
  border-top: 1px solid $color-lines;
}


.text-serif {
  font-family: $font-family-serif;
  font-size: calc(1rem - 2px);
  font-weight: 400;
  line-height: $line-height-base * 1.2;
}

.text-sans-serif {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  line-height: $line-height-base;
}

.text-sans-serif-numerals {
  font-family: $font-family-sans-serif-numbers;
  // font-size: 1rem;
  // line-height: $line-height-base;
}

.text-muted {
  color: $color-text-muted;
}

.text-alert,
.text-info {
  font-family: $font-family-sans-serif !important;
  color: #fff;
  padding: 1rem;
  margin: 2rem 0;
}

.text-alert {
  background-color: $color-cta-primary;
}

.text-info {
  background-color: $color-cta-membership;
}
*/

