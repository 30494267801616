html {
  @include res-prop("font-size", $font-size-root);
  @include font-smoothing();
  -webkit-tap-highlight-color: rgba(0,0,0,.3);
  // padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  &.website:not(.ready) body > * {
    opacity: 0;
  }
}

*::-moz-selection { background-color: rgba($color-cta-primary, .4)}
*::selection { background-color: rgba($color-cta-primary , .4)}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

textarea {
  resize: none;
}

figure {
  margin: 0;
}

label a {
  text-decoration: underline;
  color: $color-cta-primary !important;
  text-transform: uppercase;
  letter-spacing: .1em;
}

img {
  max-width: 100%;
}

a {
  svg {
    pointer-events: none;
  }
}

a[href^="tel:"] {
  text-decoration: none !important;
}
