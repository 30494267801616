html.FormBuilderIframe {
  margin: 0;
  padding: 0;
  body {
    margin: 0;
    padding: 0 10px;
    background: transparent !important;
  }
}

.FormBuilderViewport {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
}
