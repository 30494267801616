.SiteHeader {
  color: #fff;
  top: 0;
  position: fixed;
  z-index: $zindex-site-header;
  width: 100%;
}

.SiteHeader-container {
  @include make-container();
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  @include media-breakpoint-up(sm) {
    padding-top: 32px;
  }
}

.SiteHeader-logo {
  text-decoration: none !important;
  display: block;
  .show-menu & {
    pointer-events: none;
  }
}

.SiteHeader-menuToggle {
  width: 52px;
  height: 52px;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0;
  outline: none !important;
  transition: transform .3s;
  @include media-breakpoint-down(xs) {
    $scale : 42 / 52;
    transform: scale($scale);
    &[aria-pressed="true"] {
      transform: rotate(-90deg) scale($scale);
    }
  }
  &[aria-pressed="true"] {
    transform: rotate(-90deg);
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: transform .3s;
    transform: translateZ(0);
  }
  .desktop &:hover:before {
    transform: translateZ(0) scale(1.1);
  }
  span {
    height: 2px;
    display: block;
    position: absolute;
    background-color: $color-cta-primary;
    transition: transform .4s cubic-bezier("easeOutQuart");
    transform: translateZ(0);
    &:nth-child(1) {
      width: 11px;
      top: 15px;
      left: 11px;
      transform-origin: 0 50%;
    }
    &:nth-child(2) {
      width: 30px;
      top: 25px;
      left: 11px;
      transform-origin: 50% 50%;
    }
    &:nth-child(3) {
      width: 11px;
      top: 35px;
      left: 30px;
      transform-origin: 100% 50%;
    }
  }
  &[aria-pressed="true"] {
    span {
      &:nth-child(1) {
        transform: translate(5px, 0) translateZ(0) rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg) translateZ(0);
      }
      &:nth-child(3) {
        transform: translate(-5px, 0) translateZ(0) rotate(45deg);
      }
    }
  }
}

.SiteHeader-languageList {
  list-style: none;
  display: flex;
  font-size: calc(1rem - 2px);
  padding: 0;
  margin: 0 2rem 0 auto;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
  .show-menu & {
    pointer-events: none;
  }
  }

.SiteHeader-languageItem {
  &:not(:first-child) {
    margin-left: 1.5em;
    position: relative;
    &:before {
      position: absolute;
      left: -0.9em;
      content: '/';
      // padding-left: 0.5em;
      // margin-right: 0.5em;
    }
  }
  &.is-active {
    text-decoration: underline;
  }
}
