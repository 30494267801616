.BgLines {
  pointer-events: none;
  position: fixed;
  .Main & {
    z-index: $zindex-bglines;
  }
  top:  0;
  left: 0;
  width: 100%;
  height: 100vh;
  .mobile & {
    height: 100%;
  }
  overflow: hidden;
  display: none;
  canvas {
    width: 100%;
    height: 100%;
  }
  .desktop & {
    display: block;
  }
}
