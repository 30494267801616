.Text {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  @include overBgLines();
  &:first-child {
    &:before {
      content: '';
      display: block;
      height: 5vh;
      min-height: 60px;
    }
  }
}

.Text-container {
  @include make-container();
  @include make-container-max-widths();
}

.Text-row {
  @include make-row();
  @include contentSpacer("margin-top");
  &:first-child {
    margin-top: 0;
  }
}

.Text-col {
  @include make-col-ready();
  @include make-col(12);
  @include contentSpacer("margin-top");
  &:first-child {
    margin-top: 0;
  }
}

.Text-col--outer {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.Text-col--inner {
  @include reveal();
  @include media-breakpoint-up(md) {
    @include quantity-at-least(2, '*') {
      @include make-col(6);
      &:nth-child(2) {
        margin-top: 0;
        transition-delay: .2s;
      }
    }
  }
}
