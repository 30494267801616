.FormBuilder {
  .InputfieldCheckbox {
    .InputfieldHeader {
      display: none;
    }
  }
  .text-danger {
    display: none;
    & + input.form-control {
      border-color: $color-danger;
    }
    & + .custom-control label {
      color: $color-danger;
    }
  }
}
