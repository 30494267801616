.CtaList {
  @include overBgLines();
  display: flex;
  flex-wrap: wrap;
}

.CtaList-item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  background-color: color("dark-sienna");
  @include media-breakpoint-down(xs) {
    &:nth-child(even) {
      background-color: color("raw-sienna");
    }
  }
  @include media-breakpoint-up(sm) {
    flex: 1 0 50%;
    &:nth-child(4n+2),
    &:nth-child(4n+3) {
      background-color: color("raw-sienna");
    }
  }
}

.CtaList-item-link {
  min-height: 70vh;
  @media (orientation: portrait) {
    min-height: 60vh;
  }
  height: 52vw;
  @include media-breakpoint-down(xs) {
    height: 100vw;
  }
  position: relative;
  display: block;
}

.CtaList-item-title {
  position: absolute;
  top: 15%;
  left: 22%;
  right: 22%;
  color: #fff;
  z-index: 3;
  line-height: 1.2;
  @include reveal(false, true) {
    transform: translateY(-0.5em);
  }
  @include responsive-font(3.5vw, 24px, 80px);
  .CtaList-item:last-child:nth-child(odd) & {
    @include media-breakpoint-up(sm) {
      left: 11%;
      right: 11%;
    }
  }
}

.CtaList-item-imageWrapper {
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 20%;
  right: 20%;
  background-color: $media-placeholder-color;
  overflow: hidden;
  .CtaList-item:last-child:nth-child(odd) & {
    @include media-breakpoint-up(sm) {
      left: 10%;
      right: 10%;
    }
  }
}

.CtaList-item-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  @include lazyloaded();
  .desktop & {
    transform: scale(1.01);
    transition: transform .6s, opacity 1.2s;
  }
  .desktop a:hover & {
    transform: scale(1.1);
  }
}

.CtaList-item-cta {
  position: absolute;
  bottom: 8.5%;
  right: 20%;
  transform: translateY(50%);
  @include media-breakpoint-down(xs) {
    display: none;
  }
  .CtaList-item:last-child:nth-child(odd) & {
    @include media-breakpoint-up(sm) {
      right: 10%;
    }
  }
}
