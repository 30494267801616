.FeaturedList {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  @include overBgLines();
}

.FeaturedList-item {
  @include grid-spacer("m", "b", 2);
  &:last-child {
    margin-bottom: 0;
  }
}
