.Gallery {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  @include overBgLines();
  @include reveal();
}

.Gallery-wrapper {
  overflow: hidden;
}

.Gallery-container {
  @include make-container();
  @include make-container-max-widths();
  overflow: visible;
}

.Gallery-row {
  @include make-row();
}

.Gallery-col {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.Gallery-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 3px solid $color-cta-primary;
  @include media-breakpoint-down(xs) {
    .Gallery:not(.Gallery--hasTitle) & {
      display: none;
    }
  }
}

.Gallery-title {
  @include heading("1");
  margin-bottom: .5em;
  color: $color-cta-primary;
  border: none;
}

.Gallery-hint {
  flex: 0 0 auto;
  font-size: calc(1rem - 2px);
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xs) {
    display: none;
  }
  span {
    padding: 0 .5em;
    position: relative;
    top: .1em;
  }
}

.Gallery-images {
  @include make-row();
  .swiper-container {
    width: 100%;
    overflow: visible;
  }
  .swiper-slide {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(sm) {
      @include make-col(10);
    }
  }
}

.Gallery-imageWrapper {
  height: 0;
  padding-bottom: 2/3 * 100%;
  position: relative;
  overflow: hidden;
  background-color: $media-placeholder-color;
}

.Gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  @include lazyloaded();
}
