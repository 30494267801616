.custom-control-input {
  left: .2rem;
  top: .5rem;
}

.custom-control-label {
  &:before {
    border-radius: 50%;
  }
}

.custom-control-label {
  padding-top: .3em;
  padding-left: .5em;
  font-size: calc(1rem - 2px);
}
