.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.2px;
}

.Icon-svg {
  width: 100%;
  height: 100%;
}

.Icon--logo {
  fill: currentColor;
  stroke: none;
  stroke-width: 0;
  width: 180px;
  height: 44px;
  @include media-breakpoint-down(xs) {
    width: 135px;
    height: 33px;
  }
}

.Icon--arrow-left,
.Icon--arrow-right {
  stroke-linecap: round;
  width: 14px;
  height: 10px;
}
