.Map {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
}

.Map-container {
  @include make-container();
  @include make-container-max-widths();
}

.Map-row {
  @include make-row();
}

.Map-col {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.Map-wrapper {
  height: 0;
  padding-bottom: 100%;
  background-color: $media-placeholder-color;
  @include media-breakpoint-up(sm) {
    padding-bottom: 10/16 * 100%;
  }
}

.Map-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
