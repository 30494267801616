.Card {

}

.Card-link {
  text-decoration: none !important;
}

.Card-figure {
  height: 0;
  padding-bottom: 2/3 * 100%;
  position: relative;
  margin-bottom: 1rem;
  background-color: $media-placeholder-color;
  overflow: hidden;
}

.Card-image {
  @include lazyloaded(true);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  .desktop & {
    // transform: scale(1.01);
    // transition: transform .6s;
  }
  .desktop a:hover & {
    transform: scale(1.1);
  }
}

.Card-title {
  @include heading("context");
  color: $color-cta-primary;
  margin-bottom: .75rem;
}
