/*
  COLORS
 */
/*
  GRID
 */
/*

 */
/*
  BOOTSTRAP
 */
/*
  COMPONENTS
 */
/*
SVG
 */
/* Font Face */
/* font smoothing */
/* Core Utility to add margin and padding, used by the spacer mixins below */
/* Responsive spacer based on grid-gutter-width values or a given spacer map */
/* Spacer mixins based on global $spacers, accept also negative values */
/* Spacer shortcut for margin bottom */
/* Spacer shortcut for padding */
/* Spacer shortcut for margin-right */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Sporting Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #272E34;
  text-align: left;
  background-color: #e3e3e3; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: currentColor;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: currentColor;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .container {
      padding-right: 70px;
      padding-left: 70px; } }

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .container-fluid {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .container-fluid {
      padding-right: 70px;
      padding-left: 70px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .row {
      margin-right: -24px;
      margin-left: -24px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw; }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
    .col-xxl-auto {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
    .col-xxl-auto {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
    .col-xxl-auto {
      padding-right: 24px;
      padding-left: 24px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1640px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.8em + 1.5rem + 2px);
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #272E34;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #272E34;
    background-color: transparent;
    border-color: #d3ac9c;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #272E34;
  background-color: transparent; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.8; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  line-height: 1.8;
  color: #272E34;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 2rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.8em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.45em + 0.375rem);
  background-size: calc(0.9em + 0.75rem) calc(0.9em + 0.75rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.8em + 1.5rem);
  background-position: top calc(0.45em + 0.375rem) right calc(0.45em + 0.375rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") transparent no-repeat center right 1.75rem/calc(0.9em + 0.75rem) calc(0.9em + 0.75rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.8em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.45em + 0.375rem);
  background-size: calc(0.9em + 0.75rem) calc(0.9em + 0.75rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.8em + 1.5rem);
  background-position: top calc(0.45em + 0.375rem) right calc(0.45em + 0.375rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") transparent no-repeat center right 1.75rem/calc(0.9em + 0.75rem) calc(0.9em + 0.75rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.8rem;
  padding-left: 1.9rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #A6644A;
    background-color: #A6644A; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #d3ac9c; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #e3cac0;
    border-color: #e3cac0; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: -1.9rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    pointer-events: none;
    content: "";
    background-color: transparent;
    border: #fff solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: -1.9rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #A6644A;
  background-color: #A6644A; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.95rem; }
  .custom-switch .custom-control-label::before {
    left: -2.95rem;
    width: 2.45rem;
    pointer-events: all;
    border-radius: 0.7rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.2rem + 2px);
    left: calc(-2.95rem + 2px);
    width: calc(1.4rem - 4px);
    height: calc(1.4rem - 4px);
    background-color: #fff;
    border-radius: 0.7rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: transparent;
    transform: translateX(1.05rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.8em + 1.5rem + 2px);
  padding: 0.75rem 1.75rem 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #272E34;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #d3ac9c;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
    .custom-select:focus::-ms-value {
      color: #272E34;
      background-color: transparent; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.8em + 1.5rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.8em + 1.5rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #d3ac9c;
    box-shadow: 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.8em + 1.5rem + 2px);
  padding: 0.75rem 0.75rem;
  font-weight: 400;
  line-height: 1.8;
  color: #272E34;
  background-color: transparent;
  border: 1px solid #fff; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.8em + 1.5rem);
    padding: 0.75rem 0.75rem;
    line-height: 1.8;
    color: #272E34;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #e3e3e3, 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #e3e3e3, 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #e3e3e3, 0 0 0 0.2rem rgba(166, 100, 74, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #A6644A;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #e3cac0; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #A6644A;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #e3cac0; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #A6644A;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #e3cac0; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  touch-action: pan-y; }

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  touch-action: pan-x; }

@font-face {
  font-family: "Integral CF";
  font-style: "normal";
  font-weight: 500;
  src: url("../fonts/IntegralCF-Medium.woff2") format("woff2"), url("../fonts/IntegralCF-Medium.woff") format("woff"); }

@font-face {
  font-family: "Integral CF";
  font-style: "normal";
  font-weight: 700;
  src: url("../fonts/IntegralCF-Bold.woff2") format("woff2"), url("../fonts/IntegralCF-Bold.woff") format("woff"); }

@font-face {
  font-family: "Sporting Grotesque";
  font-style: "normal";
  font-weight: 400;
  src: url("../fonts/Sporting_Grotesque-Regular_web.woff2") format("woff2"), url("../fonts/Sporting_Grotesque-Regular_web.woff") format("woff"); }

@font-face {
  font-family: "Sporting Grotesque";
  font-style: "normal";
  font-weight: 700;
  src: url("../fonts/Sporting_Grotesque-Bold_web.woff2") format("woff2"), url("../fonts/Sporting_Grotesque-Bold_web.woff") format("woff"); }

html.FormBuilderIframe {
  margin: 0;
  padding: 0; }
  html.FormBuilderIframe body {
    margin: 0;
    padding: 0 10px;
    background: transparent !important; }

.FormBuilderViewport {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px; }

html {
  font-size: 14px ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3); }
  html.website:not(.ready) body > * {
    opacity: 0; }

*::-moz-selection {
  background-color: rgba(166, 100, 74, 0.4); }

*::selection {
  background-color: rgba(166, 100, 74, 0.4); }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

textarea {
  resize: none; }

figure {
  margin: 0; }

label a {
  text-decoration: underline;
  color: #A6644A !important;
  text-transform: uppercase;
  letter-spacing: .1em; }

img {
  max-width: 100%; }

a svg {
  pointer-events: none; }

a[href^="tel:"] {
  text-decoration: none !important; }

.text-white {
  color: #fff; }

.text-dark {
  color: #272E34; }

.text-danger {
  color: #B83333; }

.text-gradient {
  display: block;
  background: -webkit-radial-gradient(#d5a893, #A6644A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

/*
hr {
  @include contentSpacer("margin-top");
  @include contentSpacer("margin-bottom");
  border: 0;
  border-top: 1px solid $color-lines;
}


.text-serif {
  font-family: $font-family-serif;
  font-size: calc(1rem - 2px);
  font-weight: 400;
  line-height: $line-height-base * 1.2;
}

.text-sans-serif {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  line-height: $line-height-base;
}

.text-sans-serif-numerals {
  font-family: $font-family-sans-serif-numbers;
  // font-size: 1rem;
  // line-height: $line-height-base;
}

.text-muted {
  color: $color-text-muted;
}

.text-alert,
.text-info {
  font-family: $font-family-sans-serif !important;
  color: #fff;
  padding: 1rem;
  margin: 2rem 0;
}

.text-alert {
  background-color: $color-cta-primary;
}

.text-info {
  background-color: $color-cta-membership;
}
*/
.alert-danger {
  background-color: #B83333;
  color: #fff; }

.alert-success {
  background-color: #267853;
  color: #fff; }

.alert-danger + .alert-danger {
  margin-top: -2rem; }

.custom-control-input {
  left: .2rem;
  top: .5rem; }

.custom-control-label:before {
  border-radius: 50%; }

.custom-control-label {
  padding-top: .3em;
  padding-left: .5em;
  font-size: calc(1rem - 2px); }

.BgLines {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: none; }
  .Main .BgLines {
    z-index: 10; }
  .mobile .BgLines {
    height: 100%; }
  .BgLines canvas {
    width: 100%;
    height: 100%; }
  .desktop .BgLines {
    display: block; }

.Button,
.btn.btn-primary, .ButtonArrow {
  display: inline-block;
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  line-height: 1;
  border: none;
  min-width: 12em;
  padding: 1.2rem 2rem 1rem;
  cursor: pointer;
  text-decoration: none !important; }
  .Button:after,
  .btn.btn-primary:after, .ButtonArrow:after, .Button:before,
  .btn.btn-primary:before, .ButtonArrow:before {
    content: '';
    position: absolute; }
  .Button:before,
  .btn.btn-primary:before, .ButtonArrow:before {
    z-index: -2; }
  .Button:after,
  .btn.btn-primary:after, .ButtonArrow:after {
    z-index: -1; }
  .Button:focus,
  .btn.btn-primary:focus, .ButtonArrow:focus {
    outline: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 12px 0 rgba(0, 0, 0, 0.2); }

.Button,
.btn.btn-primary {
  text-align: center; }
  .Button:before,
  .btn.btn-primary:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, #A6644A, #E7CDC1); }
  .Button:after,
  .btn.btn-primary:after {
    background-color: #fff;
    top: -3px;
    right: 0;
    left: 0;
    bottom: 3px; }
  .desktop .Button, .desktop
  .btn.btn-primary {
    transition: color .3s; }
    .desktop .Button:after, .desktop
    .btn.btn-primary:after {
      transition: transform .3s; }
    .desktop .Button:hover, .desktop
    .btn.btn-primary:hover {
      color: #fff; }
      .desktop .Button:hover:after, .desktop
      .btn.btn-primary:hover:after {
        transform: translateY(-100%); }

.ButtonArrow {
  text-align: left;
  padding-left: 0;
  padding-right: 2rem;
  color: #A6644A !important; }
  .ButtonArrow:before {
    width: 20px;
    height: 10px;
    right: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='10px' viewBox='0 0 20 10' style='fill: none; stroke-width: 1.2px; stroke: %23A6644A'%3E%3Cline class='st0' x1='6.5' y1='5' x2='19.5' y2='5'/%3E%3Cpolyline class='st1' points='14.5,9.5 19.5,5 14.5,0.5 '/%3E%3C/svg%3E");
    z-index: 2; }
  .ButtonArrow:after {
    background-color: currentColor;
    height: 1px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1; }
  .desktop .ButtonArrow:before {
    transition: background .3s; }
  .desktop .ButtonArrow:hover:before,
  .desktop a:hover .ButtonArrow:before {
    background-position: 20px 0; }

.ButtonArrow--white {
  color: #fff !important; }
  .ButtonArrow--white:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='10px' viewBox='0 0 20 10' style='fill: none; stroke-width: 1.2px; stroke: %23fff'%3E%3Cline class='st0' x1='6.5' y1='5' x2='19.5' y2='5'/%3E%3Cpolyline class='st1' points='14.5,9.5 19.5,5 14.5,0.5 '/%3E%3C/svg%3E"); }

.Button--block {
  min-width: auto;
  width: 100%; }
  .Button--block + .Button--block {
    margin-top: 1rem; }

.Card-link {
  text-decoration: none !important; }

.Card-figure {
  height: 0;
  padding-bottom: 66.66667%;
  position: relative;
  margin-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden; }

.Card-image {
  opacity: 0;
  transform: scale(1.05);
  transition: opacity 1.2s, transform 1.8s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center; }
  .Card-image.lazyloaded {
    opacity: 1;
    transform: scale(1); }
  .desktop a:hover .Card-image {
    transform: scale(1.1); }

.Card-title {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 1rem;
  font-family: "Integral CF", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0;
  color: #A6644A;
  margin-bottom: .75rem; }

.CardList {
  margin-top: 12.8vw ;
  margin-bottom: 12.8vw ;
  position: relative;
  z-index: 11; }
  @media (min-width: 768px) {
    .CardList {
      margin-top: 12.5vw ; } }
  @media (min-width: 1200px) {
    .CardList {
      margin-top: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .CardList {
      margin-top: 192px ; } }
  @media (max-width: 767.98px) {
    .CardList {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .CardList {
      margin-bottom: 12.5vw ; } }
  @media (min-width: 1200px) {
    .CardList {
      margin-bottom: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .CardList {
      margin-bottom: 192px ; } }
  @media (max-width: 767.98px) {
    .CardList {
      margin-bottom: 4rem; } }

.CardList-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .CardList-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .CardList-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .CardList-container {
      padding-right: 70px;
      padding-left: 70px; } }

.CardList-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .CardList-col {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .CardList-col {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .CardList-col {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 992px) {
    .CardList-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }

.CardList-title {
  letter-spacing: -0.04em;
  line-height: 1.3;
  border-top: 3px solid currentColor;
  padding-top: 0.5em;
  font-size: 32px;
  color: #A6644A;
  margin-bottom: 4.26667vw ;
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s; }
  @media (min-width: 375px) and (max-width: 768px) {
    .CardList-title {
      font-size: calc( 32px + 14 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .CardList-title {
      font-size: calc( 46px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .CardList-title {
      font-size: calc( 46px + 20 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .CardList-title {
      font-size: calc( 66px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .CardList-title {
      font-size: calc( 66px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .CardList-title {
      font-size: 66px; } }
  @media (min-width: 768px) {
    .CardList-title {
      font-weight: 400; } }
  @media (min-width: 768px) {
    .CardList-title {
      margin-bottom: 4.6875vw ; } }
  @media (min-width: 1200px) {
    .CardList-title {
      margin-bottom: 4.39024vw ; } }
  @media (min-width: 1640px) {
    .CardList-title {
      margin-bottom: 72px ; } }
  @media (max-width: 767.98px) {
    .CardList-title {
      margin-bottom: 2rem; } }
  .CardList-title.is-shown {
    opacity: 1;
    transform: translateY(0); }

.CardList-item {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 4.26667vw ;
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s; }
  @media (min-width: 768px) {
    .CardList-item {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .CardList-item {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .CardList-item {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 768px) {
    .CardList-item {
      margin-top: 4.6875vw ; } }
  @media (min-width: 1200px) {
    .CardList-item {
      margin-top: 4.39024vw ; } }
  @media (min-width: 1640px) {
    .CardList-item {
      margin-top: 72px ; } }
  @media (max-width: 767.98px) {
    .CardList-item {
      margin-top: 2rem; } }
  .CardList-item.is-shown {
    opacity: 1;
    transform: translateY(0); }
  .CardList-item:nth-child(1) {
    margin-top: 0; }
  @media (min-width: 768px) {
    .CardList-item {
      flex: 0 0 50%;
      max-width: 50%; }
      .CardList-item:nth-child(2) {
        margin-top: 0;
        transition-delay: .2s; } }

.CtaList {
  position: relative;
  z-index: 11;
  display: flex;
  flex-wrap: wrap; }

.CtaList-item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  background-color: #A6644A; }
  @media (max-width: 767.98px) {
    .CtaList-item:nth-child(even) {
      background-color: #C38365; } }
  @media (min-width: 768px) {
    .CtaList-item {
      flex: 1 0 50%; }
      .CtaList-item:nth-child(4n+2), .CtaList-item:nth-child(4n+3) {
        background-color: #C38365; } }

.CtaList-item-link {
  min-height: 70vh;
  height: 52vw;
  position: relative;
  display: block; }
  @media (orientation: portrait) {
    .CtaList-item-link {
      min-height: 60vh; } }
  @media (max-width: 767.98px) {
    .CtaList-item-link {
      height: 100vw; } }

.CtaList-item-title {
  position: absolute;
  top: 15%;
  left: 22%;
  right: 22%;
  color: #fff;
  z-index: 3;
  line-height: 1.2;
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s;
  font-size: 3.5vw; }
  .is-shown .CtaList-item-title {
    opacity: 1;
    transform: translateY(0);
    transform: translateY(-0.5em); }
  @media (max-width: 685.71429px) {
    .CtaList-item-title {
      font-size: 24px; } }
  @media (min-width: 2285.71429px) {
    .CtaList-item-title {
      font-size: 80px; } }
  @media (min-width: 768px) {
    .CtaList-item:last-child:nth-child(odd) .CtaList-item-title {
      left: 11%;
      right: 11%; } }

.CtaList-item-imageWrapper {
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 20%;
  right: 20%;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden; }
  @media (min-width: 768px) {
    .CtaList-item:last-child:nth-child(odd) .CtaList-item-imageWrapper {
      left: 10%;
      right: 10%; } }

.CtaList-item-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1.2s; }
  .CtaList-item-image.lazyloaded {
    opacity: 1; }
  .desktop .CtaList-item-image {
    transform: scale(1.01);
    transition: transform .6s, opacity 1.2s; }
  .desktop a:hover .CtaList-item-image {
    transform: scale(1.1); }

.CtaList-item-cta {
  position: absolute;
  bottom: 8.5%;
  right: 20%;
  transform: translateY(50%); }
  @media (max-width: 767.98px) {
    .CtaList-item-cta {
      display: none; } }
  @media (min-width: 768px) {
    .CtaList-item:last-child:nth-child(odd) .CtaList-item-cta {
      right: 10%; } }

.Featured-figure {
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden; }
  .is-shown .Featured-figure {
    opacity: 1;
    transform: translateY(0); }
  .Featured-figure:before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%; }
  @media (min-width: 768px) {
    .Featured-figure {
      flex: 0 0 50%; }
      .FeaturedList:not(.FeaturedList--reverse) .FeaturedList-item:nth-child(even) .Featured-figure,
      .FeaturedList--reverse .FeaturedList-item:nth-child(odd) .Featured-figure {
        order: 2; } }

.Featured-imageBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transform: scale(1.05);
  transition: opacity 1.2s, transform 1.8s; }
  .Featured-imageBg.lazyloaded {
    opacity: 1;
    transform: scale(1); }

.Featured-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .Featured-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .Featured-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .Featured-container {
      padding-right: 70px;
      padding-left: 70px; } }

.Featured-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .Featured-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .Featured-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .Featured-row {
      margin-right: -24px;
      margin-left: -24px; } }

.Featured-wrapper {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .Featured-wrapper {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .Featured-wrapper {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .Featured-wrapper {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 992px) {
    .Featured-wrapper {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }
  @media (min-width: 768px) {
    .Featured-wrapper {
      display: flex; } }

.Featured-content {
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  padding-top: 8.53333vw;
  padding-bottom: 8.53333vw;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .is-shown .Featured-content {
    opacity: 1;
    transform: translateY(0); }
  @media (min-width: 768px) {
    .Featured-content {
      padding-right: 3.125vw;
      padding-left: 3.125vw; } }
  @media (min-width: 1200px) {
    .Featured-content {
      padding-right: 2.92683vw;
      padding-left: 2.92683vw; } }
  @media (min-width: 1640px) {
    .Featured-content {
      padding-right: 48px;
      padding-left: 48px; } }
  @media (min-width: 768px) {
    .Featured-content {
      padding-top: 6.25vw;
      padding-bottom: 6.25vw; } }
  @media (min-width: 1200px) {
    .Featured-content {
      padding-top: 5.85366vw;
      padding-bottom: 5.85366vw; } }
  @media (min-width: 1640px) {
    .Featured-content {
      padding-top: 96px;
      padding-bottom: 96px; } }

.FeaturedList {
  margin-top: 12.8vw ;
  margin-bottom: 12.8vw ;
  position: relative;
  z-index: 11; }
  @media (min-width: 768px) {
    .FeaturedList {
      margin-top: 12.5vw ; } }
  @media (min-width: 1200px) {
    .FeaturedList {
      margin-top: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .FeaturedList {
      margin-top: 192px ; } }
  @media (max-width: 767.98px) {
    .FeaturedList {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .FeaturedList {
      margin-bottom: 12.5vw ; } }
  @media (min-width: 1200px) {
    .FeaturedList {
      margin-bottom: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .FeaturedList {
      margin-bottom: 192px ; } }
  @media (max-width: 767.98px) {
    .FeaturedList {
      margin-bottom: 4rem; } }

.FeaturedList-item {
  margin-bottom: 8.53333vw; }
  @media (min-width: 768px) {
    .FeaturedList-item {
      margin-bottom: 6.25vw; } }
  @media (min-width: 1200px) {
    .FeaturedList-item {
      margin-bottom: 5.85366vw; } }
  @media (min-width: 1640px) {
    .FeaturedList-item {
      margin-bottom: 96px; } }
  .FeaturedList-item:last-child {
    margin-bottom: 0; }

.FormBuilder .InputfieldCheckbox .InputfieldHeader {
  display: none; }

.FormBuilder .text-danger {
  display: none; }
  .FormBuilder .text-danger + input.form-control {
    border-color: #B83333; }
  .FormBuilder .text-danger + .custom-control label {
    color: #B83333; }

.Gallery {
  margin-top: 12.8vw ;
  margin-bottom: 12.8vw ;
  position: relative;
  z-index: 11;
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s; }
  @media (min-width: 768px) {
    .Gallery {
      margin-top: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Gallery {
      margin-top: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Gallery {
      margin-top: 192px ; } }
  @media (max-width: 767.98px) {
    .Gallery {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .Gallery {
      margin-bottom: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Gallery {
      margin-bottom: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Gallery {
      margin-bottom: 192px ; } }
  @media (max-width: 767.98px) {
    .Gallery {
      margin-bottom: 4rem; } }
  .Gallery.is-shown {
    opacity: 1;
    transform: translateY(0); }

.Gallery-wrapper {
  overflow: hidden; }

.Gallery-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px;
  overflow: visible; }
  @media (min-width: 768px) {
    .Gallery-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .Gallery-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .Gallery-container {
      padding-right: 70px;
      padding-left: 70px; } }

.Gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .Gallery-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .Gallery-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .Gallery-row {
      margin-right: -24px;
      margin-left: -24px; } }

.Gallery-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .Gallery-col {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .Gallery-col {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .Gallery-col {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 992px) {
    .Gallery-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }

.Gallery-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 3px solid #A6644A; }
  @media (max-width: 767.98px) {
    .Gallery:not(.Gallery--hasTitle) .Gallery-header {
      display: none; } }

.Gallery-title {
  letter-spacing: -0.04em;
  line-height: 1.3;
  border-top: 3px solid currentColor;
  padding-top: 0.5em;
  font-size: 32px;
  margin-bottom: .5em;
  color: #A6644A;
  border: none; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Gallery-title {
      font-size: calc( 32px + 14 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Gallery-title {
      font-size: calc( 46px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Gallery-title {
      font-size: calc( 46px + 20 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Gallery-title {
      font-size: calc( 66px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Gallery-title {
      font-size: calc( 66px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Gallery-title {
      font-size: 66px; } }
  @media (min-width: 768px) {
    .Gallery-title {
      font-weight: 400; } }

.Gallery-hint {
  flex: 0 0 auto;
  font-size: calc(1rem - 2px);
  display: flex;
  align-items: center; }
  @media (max-width: 767.98px) {
    .Gallery-hint {
      display: none; } }
  .Gallery-hint span {
    padding: 0 .5em;
    position: relative;
    top: .1em; }

.Gallery-images {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .Gallery-images {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .Gallery-images {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .Gallery-images {
      margin-right: -24px;
      margin-left: -24px; } }
  .Gallery-images .swiper-container {
    width: 100%;
    overflow: visible; }
  .Gallery-images .swiper-slide {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 2.13333vw;
    padding-left: 2.13333vw;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .Gallery-images .swiper-slide {
        padding-right: 1.5625vw;
        padding-left: 1.5625vw; } }
    @media (min-width: 1200px) {
      .Gallery-images .swiper-slide {
        padding-right: 1.46341vw;
        padding-left: 1.46341vw; } }
    @media (min-width: 1640px) {
      .Gallery-images .swiper-slide {
        padding-right: 24px;
        padding-left: 24px; } }
    @media (min-width: 768px) {
      .Gallery-images .swiper-slide {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; } }

.Gallery-imageWrapper {
  height: 0;
  padding-bottom: 66.66667%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05); }

.Gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1.2s; }
  .Gallery-image.lazyloaded {
    opacity: 1; }

.Heading {
  letter-spacing: -0.04em;
  line-height: 1.3; }

.Heading--display {
  font-family: "Integral CF", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 33.41676px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--display {
      font-size: calc( 33.41676px + 15.72183 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--display {
      font-size: calc( 49.13859px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--display {
      font-size: calc( 49.13859px + 22.97347 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--display {
      font-size: calc( 72.11207px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--display {
      font-size: calc( 72.11207px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--display {
      font-size: 72.11207px; } }

.Heading--1 {
  border-top: 3px solid currentColor;
  padding-top: 0.5em;
  font-size: 32px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--1 {
      font-size: calc( 32px + 14 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--1 {
      font-size: calc( 46px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--1 {
      font-size: calc( 46px + 20 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--1 {
      font-size: calc( 66px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--1 {
      font-size: calc( 66px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--1 {
      font-size: 66px; } }
  @media (min-width: 768px) {
    .Heading--1 {
      font-weight: 400; } }

.Heading--2 {
  font-size: 30.6433px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--2 {
      font-size: calc( 30.6433px + 12.41857 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--2 {
      font-size: calc( 43.06188px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--2 {
      font-size: calc( 43.06188px + 17.3441 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--2 {
      font-size: calc( 60.40598px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--2 {
      font-size: calc( 60.40598px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--2 {
      font-size: 60.40598px; } }
  @media (min-width: 768px) {
    .Heading--2 {
      font-weight: 400; } }

.Heading--3 {
  font-size: 26.90869px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--3 {
      font-size: calc( 26.90869px + 8.41763 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--3 {
      font-size: calc( 35.32632px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--3 {
      font-size: calc( 35.32632px + 10.98509 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--3 {
      font-size: calc( 46.3114px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--3 {
      font-size: calc( 46.3114px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--3 {
      font-size: 46.3114px; } }

.Heading--4 {
  font-size: 23.62922px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--4 {
      font-size: calc( 23.62922px + 5.35114 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--4 {
      font-size: calc( 28.98036px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--4 {
      font-size: calc( 28.98036px + 6.52517 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--4 {
      font-size: calc( 35.50553px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--4 {
      font-size: calc( 35.50553px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--4 {
      font-size: 35.50553px; } }

.Heading--5 {
  font-size: 17.83019px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Heading--5 {
      font-size: calc( 17.83019px + 1.04026 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Heading--5 {
      font-size: calc( 18.87045px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Heading--5 {
      font-size: calc( 18.87045px + 1.09504 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Heading--5 {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Heading--5 {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Heading--5 {
      font-size: 19.96549px; } }

.Heading--context {
  font-size: 1rem;
  font-family: "Integral CF", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0; }

.HeroBanner {
  position: relative;
  z-index: 11;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.HeroBanner-media,
.HeroBanner-image,
.HeroBanner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.HeroBanner-media {
  background-color: rgba(0, 0, 0, 0.05); }
  .HeroBanner--parallax .HeroBanner-media {
    top: -30%;
    bottom: -30%;
    height: auto; }

.HeroBanner-image {
  opacity: 0;
  transition: opacity 1.2s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .HeroBanner-image.lazyloaded {
    opacity: 1; }

.HeroBanner-video {
  overflow: hidden; }
  .HeroBanner-video video {
    position: absolute;
    opacity: 0;
    transition: opacity 1.2s; }
    .HeroBanner-video video.lazyloaded {
      opacity: 1; }

.HeroBanner-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.HeroBanner-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .HeroBanner-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .HeroBanner-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .HeroBanner-container {
      padding-right: 70px;
      padding-left: 70px; } }

.HeroBanner-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .HeroBanner-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .HeroBanner-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .HeroBanner-row {
      margin-right: -24px;
      margin-left: -24px; } }

.HeroBanner-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .HeroBanner-col {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .HeroBanner-col {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .HeroBanner-col {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 768px) {
    .HeroBanner-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }
  @media (min-width: 992px) {
    .HeroBanner-col {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      margin-left: 8.33333%; } }

.HeroBanner-body {
  padding-top: 8.53333vw;
  padding-bottom: 8.53333vw;
  color: #fff; }
  @media (min-width: 768px) {
    .HeroBanner-body {
      padding-top: 6.25vw;
      padding-bottom: 6.25vw; } }
  @media (min-width: 1200px) {
    .HeroBanner-body {
      padding-top: 5.85366vw;
      padding-bottom: 5.85366vw; } }
  @media (min-width: 1640px) {
    .HeroBanner-body {
      padding-top: 96px;
      padding-bottom: 96px; } }

.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.2px; }

.Icon-svg {
  width: 100%;
  height: 100%; }

.Icon--logo {
  fill: currentColor;
  stroke: none;
  stroke-width: 0;
  width: 180px;
  height: 44px; }
  @media (max-width: 767.98px) {
    .Icon--logo {
      width: 135px;
      height: 33px; } }

.Icon--arrow-left,
.Icon--arrow-right {
  stroke-linecap: round;
  width: 14px;
  height: 10px; }

.Main {
  position: relative;
  z-index: 2;
  flex-grow: 2; }
  .show-menu .Main {
    pointer-events: none; }

.Map {
  margin-top: 12.8vw ;
  margin-bottom: 12.8vw ; }
  @media (min-width: 768px) {
    .Map {
      margin-top: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Map {
      margin-top: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Map {
      margin-top: 192px ; } }
  @media (max-width: 767.98px) {
    .Map {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .Map {
      margin-bottom: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Map {
      margin-bottom: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Map {
      margin-bottom: 192px ; } }
  @media (max-width: 767.98px) {
    .Map {
      margin-bottom: 4rem; } }

.Map-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .Map-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .Map-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .Map-container {
      padding-right: 70px;
      padding-left: 70px; } }

.Map-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .Map-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .Map-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .Map-row {
      margin-right: -24px;
      margin-left: -24px; } }

.Map-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .Map-col {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .Map-col {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .Map-col {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 992px) {
    .Map-col {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }

.Map-wrapper {
  height: 0;
  padding-bottom: 100%;
  background-color: rgba(0, 0, 0, 0.05); }
  @media (min-width: 768px) {
    .Map-wrapper {
      padding-bottom: 62.5%; } }

.Map-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.OverlayMenu {
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  display: none; }
  .OverlayMenu ul {
    list-style: none;
    padding: 0; }
  .OverlayMenu a {
    transition: opacity .15s; }
    .desktop .OverlayMenu a:hover {
      text-decoration: none;
      opacity: .5; }
  .OverlayMenu [data-menu-stagger] {
    opacity: 0; }

.OverlayMenu-content {
  /* ie11 fix */
  display: flex;
  flex-direction: column; }

.OverlayMenu-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .OverlayMenu-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .OverlayMenu-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .OverlayMenu-container {
      padding-right: 70px;
      padding-left: 70px; } }

.OverlayMenu-scroll {
  display: flex;
  flex-direction: column; }

.OverlayMenu-main,
.OverlayMenu-footer {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .OverlayMenu-main,
    .OverlayMenu-footer {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .OverlayMenu-main,
    .OverlayMenu-footer {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .OverlayMenu-main,
    .OverlayMenu-footer {
      margin-right: -24px;
      margin-left: -24px; } }

.OverlayMenu-main {
  padding-top: 100px; }
  @media (min-width: 768px) {
    .OverlayMenu-main {
      margin-top: auto;
      margin-bottom: auto; } }

@media (min-width: 768px) {
  .OverlayMenu-footer {
    margin-top: auto;
    margin-bottom: 2rem; } }

.OverlayMenu-block {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .OverlayMenu-block {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .OverlayMenu-block {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .OverlayMenu-block {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 992px) {
    .OverlayMenu-block {
      flex: 0 0 50%;
      max-width: 50%; } }

@media (min-width: 1200px) {
  .OverlayMenu-block--main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .OverlayMenu-block--aside {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.OverlayMenu-navList {
  margin-bottom: 1rem; }

.OverlayMenu-navItem {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 30.6433px;
  line-height: 1;
  padding: .1em 0; }
  @media (min-width: 375px) and (max-width: 768px) {
    .OverlayMenu-navItem {
      font-size: calc( 30.6433px + 12.41857 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .OverlayMenu-navItem {
      font-size: calc( 43.06188px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .OverlayMenu-navItem {
      font-size: calc( 43.06188px + 17.3441 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .OverlayMenu-navItem {
      font-size: calc( 60.40598px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .OverlayMenu-navItem {
      font-size: calc( 60.40598px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .OverlayMenu-navItem {
      font-size: 60.40598px; } }
  @media (min-width: 768px) {
    .OverlayMenu-navItem {
      font-weight: 400; } }
  .OverlayMenu-navItem a {
    color: #fff;
    opacity: 1 !important; }
    .desktop .OverlayMenu-navItem a {
      background-image: linear-gradient(currentColor, currentColor);
      background-size: 0 2px;
      background-repeat: no-repeat;
      background-position: 0 74%;
      transition: background-size 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
      .desktop .OverlayMenu-navItem a:hover {
        background-size: 100% 2px; }
  .OverlayMenu-navItem.is-active a {
    color: #A6644A; }

.OverlayMenu-contact,
.OverlayMenu-languageList {
  font-size: calc(1rem - 2px); }
  @media (min-width: 768px) {
    .OverlayMenu-contact,
    .OverlayMenu-languageList {
      font-size: 1rem; } }

.OverlayMenu-contact {
  margin-bottom: 1rem; }

.OverlayMenu-languageList {
  display: flex;
  margin-bottom: 1rem; }

.OverlayMenu-languageItem:not(:first-child) {
  margin-left: 1.5em;
  position: relative; }
  .OverlayMenu-languageItem:not(:first-child):before {
    position: absolute;
    left: -0.9em;
    content: '/'; }

.OverlayMenu-languageItem.is-active {
  text-decoration: underline; }

.OverlayMenu-socialItem {
  display: inline; }

.OverlayMenu-socialList,
.OverlayMenu-colophon {
  font-size: calc(1rem - 4px);
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .OverlayMenu-socialList,
    .OverlayMenu-colophon {
      font-size: calc(1rem - 2px); } }

.OverlayMenu-socialList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .OverlayMenu-socialList {
      flex-direction: column; } }

@media (min-width: 768px) {
  .OverlayMenu-socialItem {
    margin-right: 1em; } }

.OverlayMenu-colophon {
  margin-bottom: 1rem; }

.PageHeader {
  position: relative;
  z-index: 9;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.PageHeader-media,
.PageHeader-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05); }

.PageHeader-image {
  opacity: 0;
  transform: scale(1.05);
  transition: opacity 1.2s, transform 1.8s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .PageHeader-image.lazyloaded {
    opacity: 1;
    transform: scale(1); }

.PageHeader-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (max-width: 767.98px) {
    .PageHeader-wrapper {
      justify-content: flex-end; } }

.PageHeader-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .PageHeader-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .PageHeader-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .PageHeader-container {
      padding-right: 70px;
      padding-left: 70px; } }

.PageHeader-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw; }
  @media (min-width: 768px) {
    .PageHeader-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .PageHeader-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .PageHeader-row {
      margin-right: -24px;
      margin-left: -24px; } }

.PageHeader-body {
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 16rem;
  padding-bottom: 2rem;
  color: #fff; }
  .is-shown .PageHeader-body {
    opacity: 1;
    transform: translateY(0); }
  @media (min-width: 768px) {
    .PageHeader-body {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .PageHeader-body {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .PageHeader-body {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 768px) {
    .PageHeader-body {
      padding-top: 16rem;
      padding-bottom: 10rem;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; } }
  .PageHeader-body > * {
    max-width: 760px; }

.PageHeader-title {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-family: "Integral CF", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 33.41676px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .PageHeader-title {
      font-size: calc( 33.41676px + 15.72183 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeader-title {
      font-size: calc( 49.13859px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .PageHeader-title {
      font-size: calc( 49.13859px + 22.97347 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .PageHeader-title {
      font-size: calc( 72.11207px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeader-title {
      font-size: calc( 72.11207px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeader-title {
      font-size: 72.11207px; } }

.PageHeader-subtitle {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 17.83019px;
  margin-bottom: 0; }
  @media (min-width: 375px) and (max-width: 768px) {
    .PageHeader-subtitle {
      font-size: calc( 17.83019px + 1.04026 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .PageHeader-subtitle {
      font-size: calc( 18.87045px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .PageHeader-subtitle {
      font-size: calc( 18.87045px + 1.09504 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .PageHeader-subtitle {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .PageHeader-subtitle {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .PageHeader-subtitle {
      font-size: 19.96549px; } }

@media (max-width: 767.98px) {
  .PageHeader--imageRight .PageHeader-media,
  .PageHeader--imageLeft .PageHeader-media {
    position: relative;
    height: 0;
    padding-bottom: 90%; }
  .PageHeader--imageRight .PageHeader-wrapper,
  .PageHeader--imageLeft .PageHeader-wrapper {
    min-height: auto; }
  .PageHeader--imageRight .PageHeader-body,
  .PageHeader--imageLeft .PageHeader-body {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -46px; }
    .PageHeader--imageRight .PageHeader-body > *,
    .PageHeader--imageLeft .PageHeader-body > * {
      padding: 0 6px; } }

@media (max-width: 767.98px) {
  .PageHeader--imageRight .PageHeader-media {
    margin-left: 4.26667vw; } }

@media (min-width: 768px) {
  .PageHeader--imageRight .PageHeader-media {
    left: 30%; } }

@media (max-width: 767.98px) {
  .PageHeader--imageLeft .PageHeader-media {
    margin-right: 4.26667vw; } }

@media (min-width: 768px) {
  .PageHeader--imageLeft .PageHeader-media {
    right: 30%; } }

.PageHeader--imageLeft .PageHeader-body {
  text-align: right; }
  .PageHeader--imageLeft .PageHeader-body > * {
    margin-left: auto; }

.Prose h1, body.cke_editable h1, .Prose h2, body.cke_editable h2, .Prose h3, body.cke_editable h3, .Prose h4, body.cke_editable h4, .Prose h5, body.cke_editable h5, .Prose h6, body.cke_editable h6 {
  margin-bottom: 1em;
  color: #A6644A; }

.Prose h2, body.cke_editable h2 {
  letter-spacing: -0.04em;
  line-height: 1.3;
  border-top: 3px solid currentColor;
  padding-top: 0.5em;
  font-size: 32px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Prose h2, body.cke_editable h2 {
      font-size: calc( 32px + 14 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h2, body.cke_editable h2 {
      font-size: calc( 46px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Prose h2, body.cke_editable h2 {
      font-size: calc( 46px + 20 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Prose h2, body.cke_editable h2 {
      font-size: calc( 66px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h2, body.cke_editable h2 {
      font-size: calc( 66px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h2, body.cke_editable h2 {
      font-size: 66px; } }
  @media (min-width: 768px) {
    .Prose h2, body.cke_editable h2 {
      font-weight: 400; } }

.Prose h3, body.cke_editable h3 {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 26.90869px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Prose h3, body.cke_editable h3 {
      font-size: calc( 26.90869px + 8.41763 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h3, body.cke_editable h3 {
      font-size: calc( 35.32632px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Prose h3, body.cke_editable h3 {
      font-size: calc( 35.32632px + 10.98509 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Prose h3, body.cke_editable h3 {
      font-size: calc( 46.3114px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h3, body.cke_editable h3 {
      font-size: calc( 46.3114px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h3, body.cke_editable h3 {
      font-size: 46.3114px; } }

.Prose h4, body.cke_editable h4 {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 23.62922px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Prose h4, body.cke_editable h4 {
      font-size: calc( 23.62922px + 5.35114 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h4, body.cke_editable h4 {
      font-size: calc( 28.98036px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Prose h4, body.cke_editable h4 {
      font-size: calc( 28.98036px + 6.52517 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Prose h4, body.cke_editable h4 {
      font-size: calc( 35.50553px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h4, body.cke_editable h4 {
      font-size: calc( 35.50553px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h4, body.cke_editable h4 {
      font-size: 35.50553px; } }

.Prose h5, body.cke_editable h5 {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 17.83019px; }
  @media (min-width: 375px) and (max-width: 768px) {
    .Prose h5, body.cke_editable h5 {
      font-size: calc( 17.83019px + 1.04026 * ( ( 100vw - 375px) / 393 )); } }
  @media (min-width: 768px) and (max-width: 992px) {
    .Prose h5, body.cke_editable h5 {
      font-size: calc( 18.87045px + 0 * ( ( 100vw - 768px) / 224 )); } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .Prose h5, body.cke_editable h5 {
      font-size: calc( 18.87045px + 1.09504 * ( ( 100vw - 992px) / 208 )); } }
  @media (min-width: 1200px) and (max-width: 1640px) {
    .Prose h5, body.cke_editable h5 {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1200px) / 440 )); } }
  @media (min-width: 1640px) and (max-width: 1920px) {
    .Prose h5, body.cke_editable h5 {
      font-size: calc( 19.96549px + 0 * ( ( 100vw - 1640px) / 280 )); } }
  @media (min-width: 1920px) {
    .Prose h5, body.cke_editable h5 {
      font-size: 19.96549px; } }

.Prose h6, body.cke_editable h6 {
  letter-spacing: -0.04em;
  line-height: 1.3;
  font-size: 1rem;
  font-family: "Integral CF", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  letter-spacing: 0; }

.Prose a, body.cke_editable a {
  color: #A6644A;
  text-decoration: underline; }

.Prose > *:last-child, body.cke_editable > *:last-child {
  margin-bottom: 0 !important; }

.SiteFooter {
  padding-top: 4.26667vw;
  padding-bottom: 4.26667vw;
  position: relative;
  z-index: 11;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem; }
  @media (min-width: 768px) {
    .SiteFooter {
      padding-top: 3.125vw;
      padding-bottom: 3.125vw; } }
  @media (min-width: 1200px) {
    .SiteFooter {
      padding-top: 2.92683vw;
      padding-bottom: 2.92683vw; } }
  @media (min-width: 1640px) {
    .SiteFooter {
      padding-top: 48px;
      padding-bottom: 48px; } }

.SiteFooter-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw; }
  @media (min-width: 768px) {
    .SiteFooter-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .SiteFooter-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .SiteFooter-container {
      padding-right: 70px;
      padding-left: 70px; } }
  @media (max-width: 991.98px) {
    .SiteFooter-container > * + * {
      margin-top: 1rem; } }
  @media (min-width: 992px) {
    .SiteFooter-container {
      display: flex;
      justify-content: space-between; } }

.SiteFooter-colophon {
  margin: 0; }

.SiteFooter-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex; }

.SiteFooter-nav li {
  display: flex; }

.SiteFooter-nav li:not(:first-child):before {
  content: '';
  padding-right: 1em;
  margin-left: 1em;
  border-left: 1px solid currentColor;
  opacity: .2; }

.SiteHeader {
  color: #fff;
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%; }

.SiteHeader-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px; }
  @media (min-width: 768px) {
    .SiteHeader-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .SiteHeader-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .SiteHeader-container {
      padding-right: 70px;
      padding-left: 70px; } }
  @media (min-width: 768px) {
    .SiteHeader-container {
      padding-top: 32px; } }

.SiteHeader-logo {
  text-decoration: none !important;
  display: block; }
  .show-menu .SiteHeader-logo {
    pointer-events: none; }

.SiteHeader-menuToggle {
  width: 52px;
  height: 52px;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 0;
  outline: none !important;
  transition: transform .3s; }
  @media (max-width: 767.98px) {
    .SiteHeader-menuToggle {
      transform: scale(0.80769); }
      .SiteHeader-menuToggle[aria-pressed="true"] {
        transform: rotate(-90deg) scale(0.80769); } }
  .SiteHeader-menuToggle[aria-pressed="true"] {
    transform: rotate(-90deg); }
  .SiteHeader-menuToggle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: transform .3s;
    transform: translateZ(0); }
  .desktop .SiteHeader-menuToggle:hover:before {
    transform: translateZ(0) scale(1.1); }
  .SiteHeader-menuToggle span {
    height: 2px;
    display: block;
    position: absolute;
    background-color: #A6644A;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateZ(0); }
    .SiteHeader-menuToggle span:nth-child(1) {
      width: 11px;
      top: 15px;
      left: 11px;
      transform-origin: 0 50%; }
    .SiteHeader-menuToggle span:nth-child(2) {
      width: 30px;
      top: 25px;
      left: 11px;
      transform-origin: 50% 50%; }
    .SiteHeader-menuToggle span:nth-child(3) {
      width: 11px;
      top: 35px;
      left: 30px;
      transform-origin: 100% 50%; }
  .SiteHeader-menuToggle[aria-pressed="true"] span:nth-child(1) {
    transform: translate(5px, 0) translateZ(0) rotate(45deg); }
  .SiteHeader-menuToggle[aria-pressed="true"] span:nth-child(2) {
    transform: rotate(-45deg) translateZ(0); }
  .SiteHeader-menuToggle[aria-pressed="true"] span:nth-child(3) {
    transform: translate(-5px, 0) translateZ(0) rotate(45deg); }

.SiteHeader-languageList {
  list-style: none;
  display: flex;
  font-size: calc(1rem - 2px);
  padding: 0;
  margin: 0 2rem 0 auto; }
  @media (min-width: 768px) {
    .SiteHeader-languageList {
      font-size: 1rem; } }
  .show-menu .SiteHeader-languageList {
    pointer-events: none; }

.SiteHeader-languageItem:not(:first-child) {
  margin-left: 1.5em;
  position: relative; }
  .SiteHeader-languageItem:not(:first-child):before {
    position: absolute;
    left: -0.9em;
    content: '/'; }

.SiteHeader-languageItem.is-active {
  text-decoration: underline; }

.SvgLibrary {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden; }

.Text {
  margin-top: 12.8vw ;
  margin-bottom: 12.8vw ;
  position: relative;
  z-index: 11; }
  @media (min-width: 768px) {
    .Text {
      margin-top: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Text {
      margin-top: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Text {
      margin-top: 192px ; } }
  @media (max-width: 767.98px) {
    .Text {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .Text {
      margin-bottom: 12.5vw ; } }
  @media (min-width: 1200px) {
    .Text {
      margin-bottom: 11.70732vw ; } }
  @media (min-width: 1640px) {
    .Text {
      margin-bottom: 192px ; } }
  @media (max-width: 767.98px) {
    .Text {
      margin-bottom: 4rem; } }
  .Text:first-child:before {
    content: '';
    display: block;
    height: 5vh;
    min-height: 60px; }

.Text-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4.26667vw;
  padding-left: 4.26667vw;
  max-width: 1640px; }
  @media (min-width: 768px) {
    .Text-container {
      padding-right: 4.6875vw;
      padding-left: 4.6875vw; } }
  @media (min-width: 1200px) {
    .Text-container {
      padding-right: 4.26829vw;
      padding-left: 4.26829vw; } }
  @media (min-width: 1640px) {
    .Text-container {
      padding-right: 70px;
      padding-left: 70px; } }

.Text-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2.13333vw;
  margin-left: -2.13333vw;
  margin-top: 4.26667vw ; }
  @media (min-width: 768px) {
    .Text-row {
      margin-right: -1.5625vw;
      margin-left: -1.5625vw; } }
  @media (min-width: 1200px) {
    .Text-row {
      margin-right: -1.46341vw;
      margin-left: -1.46341vw; } }
  @media (min-width: 1640px) {
    .Text-row {
      margin-right: -24px;
      margin-left: -24px; } }
  @media (min-width: 768px) {
    .Text-row {
      margin-top: 4.6875vw ; } }
  @media (min-width: 1200px) {
    .Text-row {
      margin-top: 4.39024vw ; } }
  @media (min-width: 1640px) {
    .Text-row {
      margin-top: 72px ; } }
  @media (max-width: 767.98px) {
    .Text-row {
      margin-top: 2rem; } }
  .Text-row:first-child {
    margin-top: 0; }

.Text-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 2.13333vw;
  padding-left: 2.13333vw;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 4.26667vw ; }
  @media (min-width: 768px) {
    .Text-col {
      padding-right: 1.5625vw;
      padding-left: 1.5625vw; } }
  @media (min-width: 1200px) {
    .Text-col {
      padding-right: 1.46341vw;
      padding-left: 1.46341vw; } }
  @media (min-width: 1640px) {
    .Text-col {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 768px) {
    .Text-col {
      margin-top: 4.6875vw ; } }
  @media (min-width: 1200px) {
    .Text-col {
      margin-top: 4.39024vw ; } }
  @media (min-width: 1640px) {
    .Text-col {
      margin-top: 72px ; } }
  @media (max-width: 767.98px) {
    .Text-col {
      margin-top: 2rem; } }
  .Text-col:first-child {
    margin-top: 0; }

@media (min-width: 992px) {
  .Text-col--outer {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    margin-left: 8.33333%; } }

.Text-col--inner {
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s; }
  .Text-col--inner.is-shown {
    opacity: 1;
    transform: translateY(0); }
  @media (min-width: 992px) {
    .Text-col--inner:nth-last-child(n+2), .Text-col--inner:nth-last-child(n+2) ~ * {
      flex: 0 0 50%;
      max-width: 50%; }
      .Text-col--inner:nth-last-child(n+2):nth-child(2), .Text-col--inner:nth-last-child(n+2) ~ *:nth-child(2) {
        margin-top: 0;
        transition-delay: .2s; } }

body.cke_editable {
  margin: 1rem; }
