.alert-danger {
  background-color: $color-danger;
  color: #fff;
}

.alert-success {
  background-color: $color-success;
  color: #fff;
}

.alert-danger {
  & + & {
    margin-top: $alert-margin-bottom * -2;
  }
}
