.CardList {
  @include sectionSpacer("margin-top");
  @include sectionSpacer("margin-bottom");
  @include overBgLines();
}

.CardList-container {
  @include make-container();
  @include make-container-max-widths();
}

.CardList-col {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.CardList-title {
  @include heading("1");
  color: $color-cta-primary;
  @include contentSpacer("margin-bottom");
  @include reveal(false);
}

.CardList-item {
  @include make-col-ready();
  @include make-col(12);
  @include contentSpacer("margin-top");
  @include reveal(false);
  &:nth-child(1) {
    margin-top: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    &:nth-child(2) {
      margin-top: 0;
      transition-delay: .2s;
    }
  }
}

