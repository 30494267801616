%button {
  display: inline-block;
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  line-height: 1;
  border: none;
  min-width: 12em;
  &:after,
  &:before {
    content: '';
    position: absolute;
  }
  &:before {
    z-index: -2;
  }
  &:after {
    z-index: -1;
  }
  // height: $input-height;
  padding: 1.2rem 2rem 1rem;
  cursor: pointer;
  text-decoration: none !important;

  &:focus {
    outline: none;
    box-shadow: 0 0 6px 0 rgba(#000, .2),
                0 0 12px 0 rgba(#000, .2);
  }

}

.Button,
.btn.btn-primary {
  @extend %button;
  text-align: center;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: $gradient-linear;
  }
  &:after {
    background-color: #fff;
    top: -3px;
    right: 0;
    left: 0;
    bottom: 3px;
  }
  .desktop & {
    transition: color .3s;
    &:after {
      transition: transform .3s;
    }
    &:hover {
      color: #fff;
      &:after {
        transform: translateY(-100%);
      }
    }
  }
}

.ButtonArrow {
  @extend %button;
  text-align: left;
  padding-left: 0;
  padding-right: 2rem;
  color: $color-cta-primary !important;
  &:before {
    width: 20px;
    height: 10px;
    right: 0;
    $style: "fill: none; stroke-width: 1.2px; stroke: #{$color-cta-primary}";
    background-image: url(svg("arrow-right", $style));
    z-index: 2;
  }
  &:after {
    background-color: currentColor;
    height: 1px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .desktop &:before {
    transition: background .3s;
  }

  .desktop &:hover:before,
  .desktop a:hover &:before {
    background-position: 20px 0;
  }
}

.ButtonArrow--white {
  color: #fff !important;
  &:before {
    $style: "fill: none; stroke-width: 1.2px; stroke: #fff";
    background-image: url(svg("arrow-right", $style));
  }
}


.Button--block {
  min-width: auto;
  width: 100%;
  & + & {
    margin-top: 1rem;
  }
}
