@function svg($svg-name, $svg-style: "", $encode: true) {
  $svg : map_get($svg, $svg_name);
  $svg : str-replace($svg, "{$svg-style}", $svg-style);
  @if($encode == true) {
    $svg : svg-encode($svg);
  }
  @return $svg;
}

@mixin heading($name: null, $base: true) {

  @if $base == true {
    // font-family: $headings-font-family;
    // font-weight: $headings-font-weight;
    letter-spacing: -0.04em;
    line-height: $headings-line-height;
  }

  @if $name=="display" {
    font-family: $font-family-secondary;
    line-height: 1.2;
    letter-spacing: 0;
    @include ms-respond(font-size, 34);
  }

  @if $name=="1" {
    border-top: 3px solid currentColor;
    padding-top: 0.5em;
    @include ms-respond(font-size, 32);
    @include media-breakpoint-up(sm) {
      font-weight: 400;
    }
  }

   @if $name=="2" {
    @include ms-respond(font-size, 30);
    @include media-breakpoint-up(sm) {
      font-weight: 400;
    }
  }

  @if $name=="3" {
    @include ms-respond(font-size, 24);
  }

  @if $name=="4" {
    @include ms-respond(font-size, 18);
  }

  @if $name=="5" {
    @include ms-respond(font-size, 5);
  }

  @if $name=="context" {
    font-size: 1rem;
    font-family: $font-family-secondary;
    font-weight: 500;
    // text-transform: uppercase;
    letter-spacing: 0;
    // line-height: 1.4;
  }
}

@mixin contentSpacer($property, $factor: 1) {
  @include res-prop($property, $grid-gutter-widths, (xs:1 * $factor, sm:1.5 * $factor, lg:1.5 * $factor, xl:1.5 * $factor));
  // TODO: migliorare per evitare di sovrascrivere XS
  @include media-breakpoint-down(xs) {
    #{$property} : 2rem * $factor;
  }
}

@mixin sectionSpacer($property, $factor: 1) {
  @include res-prop($property, $grid-gutter-widths, (xs:3 * $factor, sm:4 * $factor, lg:4 * $factor, xl:4 * $factor));
  // TODO: migliorare per evitare di sovrascrivere XS
  @include media-breakpoint-down(xs) {
    #{$property} : 4rem * $factor;
  }
}

@mixin lazyloaded($scale: false) {
  opacity: 0;
  @if ($scale == true) {
    transform: scale(1.05);
    transition: opacity 1.2s, transform 1.8s;
  } @else {
    transition: opacity 1.2s;
  }
  &.lazyloaded {
    opacity: 1;
    @if ($scale == true) {
      transform: scale(1);
    }
  }
}

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin revealStart() {
  opacity: 0;
  transform: translateY(2rem);
  transition: transform 1.2s, opacity .6s;
}

@mixin revealEnd() {
  opacity: 1;
  transform: translateY(0);
}

@mixin reveal($wait-images: false, $parent: false) {
  @include revealStart();
  @if ($wait-images == true) {
    @if ($parent == true) {
      .is-shown.is-loaded & {
        @include revealEnd();
        @content;
      }
    } @else {
      &.is-shown.is-loaded {
        @include revealEnd();
        @content;
      }
    }
  } @else {
    @if ($parent == true) {
      .is-shown & {
        @include revealEnd();
        @content;
      }
    } @else {
      &.is-shown {
        @include revealEnd();
        @content;
      }
    }
  }
}

@mixin overBgLines() {
  position: relative;
  z-index: $zindex-bglines + 1;
}

@mixin underBgLines() {
  position: relative;
  z-index: $zindex-bglines - 1;
}

