.PageHeader {
  @include underBgLines();
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.PageHeader-media,
.PageHeader-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow:hidden;
  background-color: $media-placeholder-color;
}

.PageHeader-image {
  @include lazyloaded(true);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.PageHeader-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-down(xs) {
    // min-height: 100vh;
    justify-content: flex-end;
  }
}

.PageHeader-container {
  @include make-container();
  // @include make-container-max-widths();
}

.PageHeader-row {
  @include make-row();
}

.PageHeader-body {
  @include reveal(false, true);
  @include make-col-ready();
  @include make-col(12);
  padding-top: 16rem;
  padding-bottom: 2rem;
  color: #fff;
  @include media-breakpoint-up(sm) {
    padding-top: 16rem;
    padding-bottom: 10rem;
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(md) {
    // @include make-col(8);
  }
  @include media-breakpoint-up(lg) {
    // @include make-col(7);
  }
  > * {
    max-width: 760px;
  }
}

.PageHeader-title {
  @include heading("display");
}

.PageHeader-subtitle {
  @include heading("5");
  margin-bottom: 0;
}

.PageHeader--imageRight,
.PageHeader--imageLeft {
  @include media-breakpoint-down(xs) {
    .PageHeader-media {
      position: relative;
      height: 0;
      padding-bottom: 90%;
    }
    .PageHeader-wrapper {
      min-height: auto;
    }
    .PageHeader-body {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -46px;
      > * {
        padding: 0 6px;
      }
    }
  }
}

.PageHeader--imageRight {
  @include media-breakpoint-down(xs) {
    .PageHeader-media {
      margin-left: map_get($grid-container-paddings, xs);
    }
  }
  @include media-breakpoint-up(sm) {
    .PageHeader-media {
      left: 30%;
    }
  }
}

.PageHeader--imageLeft {
  @include media-breakpoint-down(xs) {
    .PageHeader-media {
      margin-right: map_get($grid-container-paddings, xs);
    }
  }
  @include media-breakpoint-up(sm) {
    .PageHeader-media {
      right: 30%;
    }
  }
  .PageHeader-body {
    text-align: right;
    > * {
      margin-left: auto;
    }
  }
}
