.Featured {
}

.Featured-figure {
  @include reveal(false, true);
  position: relative;
  background-color: $media-placeholder-color;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    .FeaturedList:not(.FeaturedList--reverse) .FeaturedList-item:nth-child(even) &,
    .FeaturedList--reverse .FeaturedList-item:nth-child(odd) & {
      order: 2;
    }
  }
}

.Featured-imageBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include lazyloaded(true);
}

.Featured-container {
  @include make-container();
  @include make-container-max-widths();
}

.Featured-row {
  @include make-row();
}

.Featured-wrapper {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  // background-color: rgba(color("turtledove"), 0.6);
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.Featured-content {
  @include reveal(false, true);
  @include grid-spacer("p", "x", 1);
  @include grid-spacer("p", "y", 2);
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
