.SiteFooter {
  @include grid-spacer("p", "y", 1);
  @include overBgLines();
  background-color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.SiteFooter-container {
  @include make-container();
  @include media-breakpoint-down(sm) {
    > * + * {
      margin-top: 1rem;
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
}

.SiteFooter-colophon {
  margin: 0;
}

.SiteFooter-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
  }
  li {
    display: flex;
  }
  li:not(:first-child):before {
    content: '';
    padding-right: 1em;
    margin-left: 1em;
    border-left: 1px solid currentColor;
    opacity: .2;
  }
}
