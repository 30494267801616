.Prose {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1em;
    color: $color-cta-primary;
  }
  h2 {
    @include heading("1");
  }
  h3 {
    @include heading("3");
  }
  h4 {
    @include heading("4");
  }
  h5 {
    @include heading("5");
  }
  h6 {
    @include heading("context");
  }
  a {
    color: $color-cta-primary;
    text-decoration: underline;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }
}
