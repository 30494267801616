.HeroBanner {
  // @include block-spacer();
  @include overBgLines();
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.HeroBanner-media,
.HeroBanner-image,
.HeroBanner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HeroBanner-media {
  // @include imagePlaceholderColor();
  background-color: $media-placeholder-color;
  .HeroBanner--parallax & {
    top: -30%;
    bottom: -30%;
    height: auto;
  }
}

.HeroBanner-image {
  @include lazyloaded();
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.HeroBanner-video {
  overflow: hidden;
  video {
    position: absolute;
    @include lazyloaded();
  }
}

.HeroBanner-wrapper {
  background-color: rgba(#000, .1);
  position: relative;
  min-height: 60vh;
  // min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HeroBanner-container {
  @include make-container();
  @include make-container-max-widths();
}

.HeroBanner-row {
  @include make-row();
  // justify-content: center;
}

.HeroBanner-col {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(1);
  }
}

.HeroBanner-body {
  @include grid-spacer("p", "y", 2);
  color: #fff;
  // max-width: 60rem;
}


